// Inicializar el proveedor de credenciales de Amazon Cognito
import { v4 as uuidv4 } from 'uuid';
AWS.config.region = 'us-east-1'; // Región
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:ffb5ed0e-0e20-40ff-8cee-be863a4f1719',
});
const aws = {
    Bucket: 'learning-peer',
    setBucket(Bucket = 'learning-peer'){    
        this.Bucket = Bucket
    },
    getInstance(){
        console.log('this.Bucket',this.Bucket);
        return new AWS.S3({
            apiVersion: '2006-03-01',
            params: {Bucket: this.Bucket}
        })
    },
    getHash(namespace = ''){
        let encrip = uuidv4()
        return `${namespace === '' ? '' : `${namespace}/`}${encrip}`
        
    },
    b64toBlob(dataURI,type = 'image/jpeg'){
        var byteString = atob(dataURI.split(',')[1])
        var ab = new ArrayBuffer(byteString.length)
        var ia = new Uint8Array(ab)
        for(var i = 0; i < byteString.length; i++){
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ab],{type})
    }
}
export default aws
